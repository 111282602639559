import { Injectable,  HostListener, EventEmitter,  Output, Inject, ElementRef, ViewChild} from '@angular/core';



import { fromEvent ,  Observable, empty } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector'


@Injectable({
  providedIn: 'root'
})
export class ScrollingAnimateService {

	@ViewChild('#navbarhideandshow') myNavElementRef: ElementRef;
	
	public scrollHeader:Observable<string>;

	public scrollVideo:Observable<boolean>;
  	public scrollMetier:Observable<boolean>;
	public scrollShowreal:Observable<boolean>;
	public scrollNavBar:Observable<boolean>;
	public scrollChiffresCles:Observable<boolean>;
	public scrollSocialWall:Observable<boolean>;
	public scrollNextBackRubrique:Observable<string>;
	public scrollpageIsLoad:Observable<boolean>;

	public resizeVideo:Observable<boolean>;
	public goTonextBackScroll:boolean=true;

	public topAnimatePosition:number=0;
	public scroll:number = 0;
	public heightElementVisiblePage:number = 0;
  	public heightElementAnimate:number = 0
  	public posTopElementAnimate:number = 0;
	public stockPosScrollTop:number=0;
	public typeAnimateHeader:string =null;
	  
	public linkPageHeader=false;

	  constructor(
		private deviceService: DeviceDetectorService,
	  ){


		  this.scrollpageIsLoad = fromEvent(window, 'load').pipe(
				map(event =>
					{
						return true;
					}
				)
			);

		  this.scrollNextBackRubrique = fromEvent(window, 'scroll').pipe(
				map(event =>
					{
						if(window.scrollY>this.stockPosScrollTop){
							this.stockPosScrollTop=window.scrollY;
							return "next";
						}
						else{
							this.stockPosScrollTop=window.scrollY;
							return "back";
						}
					}
				)
			); 
			  
			this.scrollNavBar = fromEvent(window, 'scroll').pipe(
					map(event =>
						{
							if(window.scrollY>80) {
								return true;
							}
							else{
								return false;
							}
						}
					)
				);

		  this.scrollHeader = fromEvent(window, 'scroll').pipe(
			map(event =>
					{
					////VIDEO///
					if(this.linkPageHeader){
						this.typeAnimateHeader="Showreal";
						return this.typeAnimateHeader;
					}



					//console.log('event scroll')
						this.typeAnimateHeader="Video";
						if(this.getTypeAnimateAlgoVideo(this.typeAnimateHeader)){
							//console.log(this.typeAnimateHeader);
							return this.typeAnimateHeader;
						}

					////METIER///
						this.typeAnimateHeader="Metier";
						if(this.getTypeAnimateAlgo(this.typeAnimateHeader)){
							//console.log(this.typeAnimateHeader);
							return this.typeAnimateHeader;
						}
						///CLIENT
						/*
						this.typeAnimateHeader="Client";
						if(this.getTypeAnimateAlgo(this.typeAnimateHeader)){
						//	console.log(this.typeAnimateHeader);
							return this.typeAnimateHeader;
						}
						*/
						///SHOREAL
						this.typeAnimateHeader="Showreal";
						if(this.getTypeAnimateAlgo(this.typeAnimateHeader)){
							//console.log(this.typeAnimateHeader);
							return this.typeAnimateHeader;
						}
						
						///CHIFFRES CLES
						this.typeAnimateHeader="ChiffresCles";
						if(this.getTypeAnimateAlgo(this.typeAnimateHeader)){
							//console.log(this.typeAnimateHeader);
							return this.typeAnimateHeader;
						}
						///SOCIAL WALL
						this.typeAnimateHeader="SocialWall";
						if(this.getTypeAnimateAlgo(this.typeAnimateHeader)){
							//console.log('ici');
							//console.log(this.typeAnimateHeader);
							return this.typeAnimateHeader;
						}
					
				}

			)
		 );
      this.scrollVideo = fromEvent(window, 'scroll').pipe(map(event =>{return this.getParameterAnimatePage('Video');}));
	  this.scrollMetier = fromEvent(window, 'scroll').pipe(map(event =>{return this.getParameterAnimatePage('Metier');}));
	  this.scrollShowreal = fromEvent(window, 'scroll').pipe(map(event =>{return this.getParameterAnimatePage('Showreal');}));
	  this.scrollChiffresCles = fromEvent(window, 'scroll').pipe(map(event =>{return this.getParameterAnimatePage('ChiffresCles');}));
	  this.scrollSocialWall = fromEvent(window, 'scroll').pipe(map(event =>{return this.getParameterAnimatePage('SocialWall');}));

	  this.resizeVideo = fromEvent(window, 'resize').pipe(map(event =>{
	  	return false
	  }));
  }


  getParameterAnimatePage(typeAnimate:string){
  		this.heightElementVisiblePage=window.innerHeight;
            if(typeAnimate=="Video"){
	           	 return this.getTypeAnimateAlgoVideo(typeAnimate);
            }
            else{
	            return this.getTypeAnimateAlgo(typeAnimate);
			}
	}

	getTypeAnimateAlgoVideo(typeAnimate:string){
		if(this.linkPageHeader){
			return false;
		}
		this.posTopElementAnimate=window.document.getElementById(typeAnimate).offsetTop;
		this.heightElementAnimate=window.document.getElementById(typeAnimate).offsetHeight;
		return window.scrollY>=this.posTopElementAnimate && window.scrollY<this.posTopElementAnimate+(this.heightElementAnimate/4);
	}

	getTypeAnimateAlgo(typeAnimate:string){
		if(this.linkPageHeader){
			return false;
		}
		this.posTopElementAnimate=window.document.getElementById(typeAnimate).offsetTop;
		this.heightElementAnimate=window.document.getElementById(typeAnimate).offsetHeight;
		if(this.deviceService.isMobile()){
			return window.scrollY>this.posTopElementAnimate-(this.heightElementVisiblePage-200) && window.scrollY<this.posTopElementAnimate+this.heightElementAnimate-100;
		}
		else{
			return window.scrollY>this.posTopElementAnimate-(this.heightElementVisiblePage-(this.heightElementVisiblePage/10)) && window.scrollY<this.posTopElementAnimate+(this.heightElementAnimate-(this.heightElementVisiblePage/10));
		}
	}
	

}

