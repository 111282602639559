import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { Api } from '../model/Api.model';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public URL_MEDIA_API:string;
  public api: BehaviorSubject<Api[]> = new BehaviorSubject(null);
  public commonobserval: Observable<any> = new Observable(null);
  public jsonCommon=null;
  
  constructor(private http: HttpClient) { 
    this.URL_MEDIA_API=environment.URL_MEDIA_API;
    this.initCreations();
  }
  public getJsonCommon(): Observable<any> {
    if(this.jsonCommon==null){
      this.jsonCommon= this.http.get(this.URL_MEDIA_API+'/api.json');
      return this.jsonCommon;
    }
    else{
      return this.jsonCommon;
    }
  }

  initCreations():void{
    this.http.get(this.URL_MEDIA_API+'/api.json').subscribe( 
              (api: Api[]) => {
                    this.api.next(api)
                },
            );

  }
 
}