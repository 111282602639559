import { Component, OnInit, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ScrollingAnimateService } from '../shared/services/scrolling-animate.service';
import { Meta, Title } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Api, Creations } from '../shared/model/Api.model';

import { CommonService } from '../shared/services/common.service';
import { SpinnerService } from '../shared/services/spinner.service';
import { environment } from '../../environments/environment';


declare function carouselCretaionBrother(nbCreation:number): void;

@Component({
  selector: 'app-casagence',
  templateUrl: './casagence.component.html',
  styleUrls: ['./casagence.component.css'],

  
})
export class CasagenceComponent implements OnInit, AfterViewInit {
  public slug: string;
  public creations: Creations[];
  public creationSlug:string;
  public URL_MEDIA_API:string;
  public URL_SHARE:string;
  public URL_SITE:string;
  public NbCreationBrother:number = 0;
  public deviceInfo:any=null;
  public isMobile:boolean=false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private scrollingAnimateService:ScrollingAnimateService,
    private title: Title,
    public spinnerService:SpinnerService,
    public commonService:CommonService,
    private el: ElementRef,
    private renderer:Renderer2,
    private meta: Meta,
    private deviceService: DeviceDetectorService,

  ) { }

  ngOnInit() {

    if(this.deviceService.isMobile()){
      this.meta.updateTag({ name: 'viewport', content: 'width=360' });
      this.isMobile=true;
    }
    else{
      this.meta.updateTag({ name: 'viewport', content: 'width=1365,maximum-scale=1.0' });
      this.isMobile=false;
    }

 
    this.URL_MEDIA_API=environment.URL_MEDIA_API;
    this.URL_SHARE=environment.URL_SHARE;
    this.URL_SITE=environment.URL_SITE;
    this.scrollingAnimateService.linkPageHeader=true;
    this.activatedRoute.paramMap.subscribe((paramMap: ParamMap) => {
      this.slug = paramMap.get('slug');
      this.spinnerService.typePageCas(true);
      this.commonService.api.subscribe((api: Api[]) =>{
        if(api!=null){
          this.creations=api['creations'].filter(x => x.slug == this.slug );
          this.title.setTitle(this.creations[0].projet_name);
          this.renderer.setStyle(this.el.nativeElement.ownerDocument.body,'backgroundColor',this.creations[0].backgroundcolorpage);
          this.NbCreationBrother=this.creations[0].data_creations_brother.length;
          carouselCretaionBrother(this.NbCreationBrother);
        }    
      })
     
    });
  }
  ngAfterViewInit() {
    setTimeout(function(){
      window.scrollTo(0, 0)
      }, 1200);
  }
  shareSocialTS(url:string){
      window.open(url,'share','menubar=no,location=no,resizable=no,scrollbars=no,status=no,width=600px,height=600px');

  }
}
