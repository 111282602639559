import { Routes,RouterModule  } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { ContenaireSinglepageComponent } from './contenaire-singlepage/contenaire-singlepage.component';
import { CasagenceComponent } from './casagence/casagence.component';



const appRoutes: Routes = [
  { path : "", pathMatch: 'full', component: ContenaireSinglepageComponent,data: {animation: 'HomePage'}},
  { path : "creations/:slug", component: CasagenceComponent, data: {animation: 'CreationsPage'} },

];

export const AppRoutingModule: ModuleWithProviders = RouterModule.forRoot(appRoutes);
