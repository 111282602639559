
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';





import { DeviceDetectorModule } from 'ngx-device-detector';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


import { AppComponent } from './app.component';
import { ContenaireSinglepageComponent } from './contenaire-singlepage/contenaire-singlepage.component';
import { PresentationComponent } from './contenaire-singlepage/presentation/presentation.component';
import { ChiffresClesComponent } from './contenaire-singlepage/chiffres-cles/chiffres-cles.component';
import { LescasComponent } from './contenaire-singlepage/lescas/lescas.component';
import { SocialMediaComponent } from './contenaire-singlepage/social-media/social-media.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { MetierComponent } from './contenaire-singlepage/metier/metier.component';
import { ShowrealComponent } from './contenaire-singlepage/showreal/showreal.component';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { SpinnerService } from './shared/services/spinner.service';
import { I1 } from './shared/interceptors';
import { SafeHtmlPipe } from "./shared/pipe/pipe.safehtml";
import { CleanHtmlSocialWall } from "./shared/pipe/cleanHtmlSocialwall.pipe";
import { CleanHtmlAgence } from "./shared/pipe/cleanHtmlAgence.pipe";


import { SortByPipe } from "./shared/pipe/sortby.pipe";
import { CasagenceComponent } from './casagence/casagence.component';
import { LoaderComponent } from './loader/loader.component';



@NgModule({
  declarations: [
    AppComponent,
    ContenaireSinglepageComponent,
    PresentationComponent,
    ChiffresClesComponent,
    LescasComponent,
    SocialMediaComponent,
    HeaderComponent,
    FooterComponent,
    MetierComponent,
    ShowrealComponent,
    SafeHtmlPipe,
    CleanHtmlSocialWall,
    SortByPipe,
    CleanHtmlAgence,
    CasagenceComponent,
    LoaderComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,    
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    DeviceDetectorModule.forRoot()
  ],
  providers: [
    SpinnerService,   
    {
      provide: HTTP_INTERCEPTORS,
      useClass: I1,
      multi: true
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
