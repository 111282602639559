import { Component, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { ScrollingAnimateService } from '../../shared/services/scrolling-animate.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router, ActivatedRoute, NavigationExtras, ParamMap } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy, OnChanges{

  public activeMetier:boolean=false;
  public activeClient:boolean=false;
  public activeChiffresCles:boolean=false;
  public activeSosialWall:boolean=false;
  public activeShowreal:boolean=false
  public beginanimate:string;
  public classNavBarStart: string = 'navbar navbar-expand-lg navbar-light bg-light'
  public classNavBar: string;
  public timeoutNavaBar:any;
  public decaleHeightNavbar:number = 79;
  public animateNavBarre:boolean=false;
  public positionEnd:number=0;

  private subScrollpageIsLoad: any;
  private subScrollNextBackRubrique: any;
  private subScrollHeader: any;
  private subScrollNavBar: any;
  private intervalScroll: any;
  public rub: string;
  public slug: string;
    constructor(
        private scrollingAnimateService:ScrollingAnimateService,
        private deviceService: DeviceDetectorService,
        private router: Router, 
        private route: ActivatedRoute
        
    ) { 
   

    }

  ngOnInit() {

          if(this.deviceService.isMobile()){
            this.decaleHeightNavbar=0;
          }
          else{
            this.decaleHeightNavbar=0;
          }


          this.classNavBar=this.classNavBarStart;
                this.subScrollpageIsLoad=this.scrollingAnimateService.scrollpageIsLoad.subscribe(
                  retourLoad=>{
                      this.animateNavBarre=false;
                        this.route.fragment.subscribe((fragment: string) => {
                            if(fragment){
                              this.scrollingAnimateService.linkPageHeader=false;
                              setTimeout(() => this.linkSinglePage(fragment), 1500);
                            }
                    })
                  }
                )      
        this.subScrollHeader=this.scrollingAnimateService.scrollHeader.subscribe(
          beginanimate => {
            if(!this.scrollingAnimateService.linkPageHeader){
              this.activeMetier=(beginanimate=='Metier');
              this.activeShowreal=(beginanimate=='Showreal');
              if(!this.activeShowreal) {
                  this.activeShowreal = (beginanimate == 'Client');
              }
              this.activeChiffresCles=(beginanimate=='ChiffresCles');
              this.activeSosialWall=(beginanimate=='SocialWall');
              }
            }
        );

       

  }





  linkSinglePage(id: string): void {

    if(this.scrollingAnimateService.linkPageHeader){
      this.router.navigate(['/'],{fragment:id});
    }
    else{   
        this.animateNavBarre=false;
        const el: HTMLElement|null = document.getElementById(id);
        if (el) {
          this.positionEnd=el.offsetTop-this.decaleHeightNavbar;
          if(this.deviceService.browser=="MS-Edge" || this.deviceService.browser=="IE"){
            setTimeout(() => window.scrollTo(0, this.positionEnd), 500);
          }
          else{
            setTimeout(() => window.scrollTo({top: this.positionEnd, behavior: "smooth"}), 500);
          }
    
        }
        else{
          setTimeout(() => this.linkSinglePage(id), 500);

        }
      }
  }

 



 
  ngOnDestroy() {
    this.subScrollpageIsLoad.unsubscribe()
    this.subScrollNextBackRubrique.unsubscribe()
    this.subScrollHeader.unsubscribe();
    this.subScrollNavBar.unsubscribe();
  }


  ngOnChanges(){
    console.log('change');
  }


}
