import { Component, HostBinding, ViewChild } from '@angular/core';
import { fadeAnimation } from './shared/animations/fade.animation';

import {RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [fadeAnimation]
})

export class AppComponent {
  title = 'whowhywhat';

  onDeactivate() {
    //document.body.scrollTop = 0;
    // Alternatively, you can scroll to top by using this other call:
   //  window.scrollTo(0, 0)
  }

  public prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
