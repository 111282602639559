import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';

@Pipe({name: 'cleanHtmlAgence'})
export class CleanHtmlAgence implements PipeTransform {
    constructor(
        private sanitized: DomSanitizer,
        private deviceService: DeviceDetectorService,
        ) {
        
    }

    public p:string;
    transform(value: string) {
      if(this.deviceService.isMobile()){
        value=value.replace(/<b>/g, ' <b style="font-size:30px;line-height: 30px;">');
        }
      else{
        value=value.replace(/<b>/g, ' <b style="font-size:50px;line-height: 50px;">');
        }
       return this.sanitized.bypassSecurityTrustHtml(value);
    }
}