import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ScrollingAnimateService } from '../../shared/services/scrolling-animate.service';
import { DeviceDetectorService } from 'ngx-device-detector'

@Component({
  selector: 'app-showreal',
  templateUrl: './showreal.component.html',
  styleUrls: ['./showreal.component.css']
})
export class ShowrealComponent implements OnInit {
  public beginanimate:boolean;
  public endanimate:boolean;
  public videoEl:any;
  public isIphone:boolean;
  constructor(
      private scrollingAnimateService:ScrollingAnimateService,
      private deviceService: DeviceDetectorService
  ) { }

  ngOnInit() {
    this.isIphone=this.deviceService.device=='iPhone';
  	this.beginanimate = false;
	  this.endanimate = true;
    
    this.scrollingAnimateService.scrollShowreal.subscribe(
        beginanimate => {
      
          this.videoEl = document.getElementById('videoplayershoreal');
          if(beginanimate){
            if (this.videoEl) {
              if(!this.isIphone){
                this.videoEl.play();
              }
            }
          }
          else{
            if (this.videoEl) {
              if(!this.isIphone){
                this.videoEl.pause();
              }
            }
          }

          if(!this.beginanimate){
            this.beginanimate = beginanimate;
            this.endanimate = !beginanimate;
          }
        }
    );
  }

  ngAfterViewInit (){
    this.videoEl = document.getElementById('videoplayershoreal');
      if (this.videoEl) {
          this.videoEl.muted = true;
          this.videoEl.loop = true;
          this.videoEl.controls = true;
          if(!this.isIphone){
            this.videoEl.play();
          }
      }
  }
}
