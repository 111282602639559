import { trigger, animate, transition, style, query, group,
    animateChild } from '@angular/animations';

export const fadeAnimation =
      trigger('routeAnimations', [
        transition('HomePage => CreationsPage', [
          group([
            query(
              ':enter',
              [
                style({
                  opacity: 0,
                  transform: 'translateY(0) rotate(0)'
                }),
                animate(
                  '1.5s',
                  style({ opacity: 1, transform: 'translateY(0) rotate(0)' })
                ),
                animateChild()
              ],
              { optional: true }
            ),
            query(
              ':leave',
              [animate('1.5s', style({ opacity: 0 })), animateChild()],
              { optional: true }
            )
          ])
        ]),
        transition('CreationsPage => HomePage', [
          group([
            query(
              ':enter',
              [
                style({
                  opacity: 0,
                  transform: 'translateY(0) rotate(0)'
                }),
                animate(
                  '1.s',
                  style({ opacity: 1, transform: 'translateY(0) rotate(0)' })
                ),
                animateChild()
              ],
              { optional: true }
            ),
            query(
              ':leave',
              [animate('1.5s', style({ opacity: 0 })), animateChild()],
              { optional: true }
            )
          ])
        ]),
        transition('CreationsPage => CreationsPage', [
          group([
            query(
              ':enter',
              [
                style({
                  opacity: 0,
                  transform: 'translateY(0) rotate(0)'
                }),
                animate(
                  '1.s',
                  style({ opacity: 1, transform: 'translateY(0) rotate(0)' })
                ),
                animateChild()
              ],
              { optional: true }
            ),
            query(
              ':leave',
              [animate('1.5s', style({ opacity: 0 })), animateChild()],
              { optional: true }
            )
          ])
        ])
      ])
    
