import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ScrollingAnimateService } from '../../shared/services/scrolling-animate.service';
import { Api, Agence } from '../../shared/model/Api.model';
import { CommonService } from '../../shared/services/common.service';
import { CleanHtmlAgence } from "../../shared/pipe/cleanHtmlAgence.pipe";

@Component({
  selector: 'app-chiffres-cles',
  templateUrl: './chiffres-cles.component.html',
  styleUrls: ['./chiffres-cles.component.css'],  
})
export class ChiffresClesComponent implements OnInit {
  public beginanimate:boolean; 
  public endanimate:boolean; 
  public agence:Agence[];

  constructor(
		private scrollingAnimateService:ScrollingAnimateService,
		public commonService:CommonService,
  	) { }

  ngOnInit() {
      	this.beginanimate = false;
		this.endanimate = false;
		   

		this.commonService.api.subscribe((api: Api[]) =>{
		if(api!=null){
			this.agence=api['agence'][0];
			}   
		})

	   	this.scrollingAnimateService.scrollChiffresCles.subscribe(
	      beginanimate => {
			  if(!this.beginanimate){
	        	  	this.beginanimate = beginanimate;
	          		this.endanimate = !beginanimate;
		  		}
	         // console.log('[METIER] => ' +this.beginanimate);
	      }
	    );
  }

}
