import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public URL_MEDIA_API:string;
  constructor() { }

  ngOnInit() {
    this.URL_MEDIA_API=environment.URL_MEDIA_API;
  }

}
