import { Component, OnInit, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ScrollingAnimateService } from '../shared/services/scrolling-animate.service';
import { environment } from '../../environments/environment';

import { PresentationComponent} from './presentation/presentation.component';
import { LescasComponent} from './lescas/lescas.component';
import { ChiffresClesComponent} from './chiffres-cles/chiffres-cles.component';
import { SocialMediaComponent} from './social-media/social-media.component';
declare function carouselHomepage(): void;
@Component({
  selector: 'app-contenaire-singlepage',
  templateUrl: './contenaire-singlepage.component.html',
  styleUrls: ['./contenaire-singlepage.component.css'],

})
export class ContenaireSinglepageComponent implements OnInit {
    public deviceInfo:any=null;
    public shoComponentCreation:boolean = environment.opencreations;
    constructor(
	   	private title: Title,
	    private meta: Meta,
      private deviceService: DeviceDetectorService,
      private scrollingAnimateService:ScrollingAnimateService,
      ){
      
	   }

  ngOnInit() {
    this.scrollingAnimateService.linkPageHeader=false;
     if(this.deviceService.isMobile()){
          this.meta.updateTag({ name: 'viewport', content: 'width=360' });
        }
        else{
          this.meta.updateTag({ name: 'viewport', content: 'width=1365,maximum-scale=1.0' });
       }
       carouselHomepage();
    }

}
