import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Injectable()
export class SpinnerService {
  private visible$ = new BehaviorSubject<boolean>(false);
  public pageCas:boolean=false;
  private nbReqHttp:number=0;
  private nbLoadApi:number=2;
  private isload:boolean=false;
  constructor(
    private activatedRoute: ActivatedRoute,


  ) { 


    

  }
  typePageCas(pagecas:boolean){
    if(pagecas){
      this.nbLoadApi=1;
    }
  }
 
  show() {
    this.visible$.next(true);
  }

  hide() {
   this.nbReqHttp=this.nbReqHttp+1;
   if(this.nbReqHttp==this.nbLoadApi || this.isload){
      this.isload=true;
      this.visible$.next(false);
    }
  }

  isVisible(): Observable<boolean> {
    return this.visible$.asObservable().pipe(share());
  }
}
