import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { SocialWall } from '../../shared/model/SocialWall.model';

@Injectable({
  providedIn: 'root'
})

export class SocialwallService {
  public socialWall: BehaviorSubject<SocialWall[]> = new BehaviorSubject(null);
  public i:number=0;
  public arrayidDeleteSocialWall:Array<string>=[];

  constructor(private http: HttpClient) {
      this.initSocialWall();
  }

  initSocialWall():void{
    //this.http.get('https://socialmedia.whowhywhat.fr/wp-admin/admin-ajax.php?shop=socialmedia.whowhywhat.fr&action=fetch_posts&stream-id=2&disable-cache=&hash=1594823501.7de2196c6e635aeeeb5ed055c9a790e9b7d8ecc8b5389bf466ac52fb02f9c3f83d6fa17e3519a391f5b7ebb3b2386c22&page=0&preview=0&token=').subscribe( (socialWall: SocialWall[]) => {
    //this.http.get('https://socialmedia.whowhywhat.fr/wp-admin/admin-ajax.php?shop=socialmedia.whowhywhat.fr&action=fetch_posts&stream-id=2&disable-cache=&hash=1632557923.e12b1c465708adc3fc90d7fde71be2dcb7d8ecc8b5389bf466ac52fb02f9c3f83d6fa17e3519a391f5b7ebb3b2386c22&page=0&preview=0&token=&boosted=1').subscribe( (socialWall: SocialWall[]) => {
    //this.http.get('https://socialmedia.whowhywhat.fr/wp-admin/admin-ajax.php?shop=socialmedia.whowhywhat.fr&action=fetch_posts&stream-id=2&disable-cache=&hash=1636146547.3b90d9232147deee8f7f5f0b26f6c4b1b7d8ecc8b5389bf466ac52fb02f9c3f83d6fa17e3519a391f5b7ebb3b2386c22&page=0&preview=0&token=&boosted=1').subscribe( (socialWall: SocialWall[]) => {

    this.http.get('https://socialmedia.whowhywhat.fr/wp-admin/admin-ajax.php?shop=socialmedia.whowhywhat.fr&action=fetch_posts&stream-id=2&disable-cache=&hash=1636614570.edc91e14e881b99c7d77913cb0418f69b7d8ecc8b5389bf466ac52fb02f9c3f83d6fa17e3519a391f5b7ebb3b2386c22&page=0&preview=0&token=&boosted=1').subscribe( (socialWall: SocialWall[]) => {

    // this.http.get('https://socialmedia.whowhywhat.fr/wp-admin/admin-ajax.php?action=fetch_posts&stream-id=2&disable-cache=&hash=1584009276.4f764fa722ba59c7a300b04a4f2f9d6bee45a79fcb21e8325da6cc1e5c57cb041caa9c84b7a1da7b64fcf91b6942ce2d&page=0&preview=0').subscribe( (socialWall: SocialWall[]) => {
   this.i=0;
      for (let order of socialWall['items']) {
          if(order.img != undefined){
          }
          else{
            this.arrayidDeleteSocialWall.push(socialWall['items'][this.i])
          }
          this.i++;
      }
    for (let lstDeleteSocialWall of this.arrayidDeleteSocialWall) {
      socialWall['items'].splice(socialWall['items'].indexOf(lstDeleteSocialWall), 1);
    }
    this.socialWall.next(socialWall['items']);
    });
  }
}
