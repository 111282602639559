import { Component, OnInit } from '@angular/core';
import { ScrollingAnimateService } from '../../shared/services/scrolling-animate.service';
import { Api, Metier, Metiercategories } from '../../shared/model/Api.model';
import { CommonService } from '../../shared/services/common.service';
@Component({
  selector: 'app-metier',
  templateUrl: './metier.component.html',
  styleUrls: ['./metier.component.css']
})
export class MetierComponent implements OnInit {
  public beginanimate:boolean; 
  public endanimate:boolean; 
  public metier: Metier[];
  public metiercategorie:Metiercategories[];




  public metierUnique:any;
  public title:string ='';
  public description:string = '';
	constructor(
		private scrollingAnimateService:ScrollingAnimateService,
		public commonService:CommonService,
  	) { }

  ngOnInit() {

		this.beginanimate = false;
		this.endanimate = true;
		   this.commonService.api.subscribe((api: Api[]) =>{
			if(api!=null){
				this.metier=api['metier'];
				this.title=this.metier[0].titre;
				this.description=this.metier[0].description
				this.metiercategorie=this.metier[0].metiercategories;	
		  		}   
		  	})
	  



		
		
		
		  this.scrollingAnimateService.scrollMetier.subscribe(
			beginanimate => {
				if(!this.beginanimate){
					this.beginanimate = beginanimate;
					this.endanimate = !beginanimate;
				}
			}
		  );
		
	
  }

}
