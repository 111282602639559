import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ScrollingAnimateService } from '../../shared/services/scrolling-animate.service';
import { DeviceDetectorService } from 'ngx-device-detector'
import { Title } from '@angular/platform-browser';
import {SpinnerService} from '../../shared/services/spinner.service'
@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.css'],
})
export class PresentationComponent implements OnInit, AfterViewInit  {
  public beginanimate:boolean; 
  public endanimate:boolean;
  public videoEl2:any;
  public styleHeightVideo:number=0;
  public isIphone:boolean;
  public loadingPage:boolean =true;

  @ViewChild('videoPlayer') videoplayer: ElementRef;
  constructor( 
    private scrollingAnimateService:ScrollingAnimateService,
    private deviceService: DeviceDetectorService,
    private title: Title,
    public spinner: SpinnerService
    ) { }

  ngOnInit() {
    this.title.setTitle('whowhywhat, agence indépendante : publicité, activation digitale, social media | Agence de communication - stratégie, création et déploiement 360 - Paris');
    this.isIphone=this.deviceService.device=='iPhone';
      this.resizeVideoHeight();
      this.beginanimate = false;
      this.spinner.isVisible().subscribe((isVisible: boolean) =>{
        if(this.loadingPage){
          this.loadingPage=false;
        }
        else{
          if(!isVisible){
            this.beginanimate = true;
            this.endanimate = false;
          }
        }
      })




      this.scrollingAnimateService.resizeVideo.subscribe(
          resize => {
              this.resizeVideoHeight();
          }
      );
    }


    resizeVideoHeight(){
      if(this.deviceService.isMobile()) {
            this.styleHeightVideo=158;
      }
      else{
            this.videoEl2 = document.getElementsByClassName('container-video')
            this.styleHeightVideo = Math.round(window.innerWidth / 16 * 9) - 5;
       }
    }

      ngAfterViewInit (){
          this.videoEl2 = document.getElementById('videoplayeChewing');
            if (this.videoEl2) {
                this.videoEl2.muted = true;
                this.videoEl2.loop = true;
                this.videoEl2.play();
            }
        }
}
