import { Component, OnInit } from '@angular/core';
import {SpinnerService} from '../shared/services/spinner.service'

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {


  constructor(
    public spinner: SpinnerService
  ) { }

  ngOnInit() {
  }

}
