import { Component, OnInit } from '@angular/core';


import { Api, Creations } from '../../shared/model/Api.model';

import { CommonService } from '../../shared/services/common.service';
import { environment } from '../../../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { debug } from 'util';
@Component({
  selector: 'app-lescas',
  templateUrl: './lescas.component.html',
  styleUrls: ['./lescas.component.css'],
 // styles: ['body { background:red;; }']
})
export class LescasComponent implements OnInit {
  public creations: Creations[];
  public api: Api[];
  public URL_MEDIA_API:string;
  public i:number=0;
  public arrayCreations:Creations[]
  public order:string;
  public currentPage:number;
  public nombreDeCas:number;
  public isShowPlusDeCas:boolean;
  public isMobile:boolean=false;
  public linkCas:boolean=true;
  

  constructor(
    public commonService:CommonService,
    private deviceService: DeviceDetectorService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.currentPage=1;
    this.nombreDeCas=0;
    this.URL_MEDIA_API=environment.URL_MEDIA_API;
    this.isMobile=this.deviceService.isMobile();
    this.commonService.api.subscribe((api: Api[]) =>{
      if(api!=null){
        this.creations=api['creations'].filter(x => x.online == true);
        this.nombreDeCas=this.creations.length;
        this.ShowPlusDeCas();
      }    
    })
  }

  ShowPlusDeCas(){
    if(
      this.currentPage==1 && this.nombreDeCas>8 ||
      this.currentPage==2 && this.nombreDeCas>16 ||
      this.currentPage==3 && this.nombreDeCas>24 ||
      this.currentPage==4 && this.nombreDeCas>32 ||
      this.currentPage==5 && this.nombreDeCas>40
      ){
     this.isShowPlusDeCas=true;
    }
    else{
      this.isShowPlusDeCas=false;
    }
  }

  clickPlusDeCas(){
    this.currentPage=this.currentPage+1;
    this.ShowPlusDeCas();

  }

  clickMoinsDeCas(){
    this.currentPage=1;
    this.ShowPlusDeCas();
  }

  isShowCas(weightCas:number){
    if(
      this.currentPage==1 && weightCas<=8 ||
      this.currentPage==2 && weightCas<=16 ||
      this.currentPage==3 && weightCas<=24 ||
      this.currentPage==4 && weightCas<=32 ||
      this.currentPage==5 && weightCas<=40
      ){
      return true;
    }
    else{
      return false;
    }
  }

  classActive(){
    return 'active';
  }


  stoptocas(){
    this.linkCas=false;
  }
  destopToCas(){
    this.linkCas=true;
  }
  gotocas(slug:string){
    setTimeout( () => { 
        if(this.linkCas){
          this.router.navigate(["/creations/"+slug]);
        }
      }, 100 );
  }

}
