import { Component, OnInit } from '@angular/core';
import { ScrollingAnimateService } from '../../shared/services/scrolling-animate.service';
import { SocialWall } from '../../shared/model/SocialWall.model'
import { SocialwallService } from '../../shared/services/socialwall.service';
import { SortByPipe } from "../../shared/pipe/sortby.pipe";
import { DeviceDetectorService } from 'ngx-device-detector';



@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css'],

})
export class SocialMediaComponent implements OnInit {
  public socialWalls: SocialWall[];
  public beginanimate:boolean; 
  public endanimate:boolean; 
  public isMobile:boolean=false;
  constructor(
    private socialwallService:  SocialwallService, 
    private scrollingAnimateService:ScrollingAnimateService,
    private deviceService: DeviceDetectorService
  ) { }

  ngOnInit() {
    this.socialwallService.socialWall.subscribe((socialWalls: SocialWall[]) =>{
        this.socialWalls=socialWalls;
        this.isMobile=this.deviceService.isMobile();
        
        if(this.socialWalls!=null){
         //console.log(this.socialWalls);
        }
    }) 
    this.beginanimate = true;
       this.endanimate = false;
       /*
	   	this.scrollingAnimateService.scrollSocialWall.subscribe(
	      beginanimate => {
	          this.beginanimate = beginanimate;
	          this.endanimate = !beginanimate;
	         // console.log('[SOCIAL WALL] => ' +this.beginanimate);
	      }
      );
      */
  }
  getSlidePage(page:number, index:number){
    if(this.deviceService.isMobile()){
      return true;
    }
    else{
     if(page==0 && index<4 || 
      page==1 && index>4 && index<=8 ||
      page==2 && index>8 && index<=12 ||
      page==3 && index>12 && index<=16||
      page==4 && index>16 && index<=20||
      page==5 && index>20 && index<=24  
      ){
        return true;
     }
     else{
        return false;
     }
    }
  }

  createLoop(number){
    var items: number[] = [];
    if(this.deviceService.isMobile()){
      items.push(1);
    }
    else{
      for(var i:number = 1; i <= number; i++){
        items.push(i);
      }
    }
 
    return items;
  }
 
  classActive(){
    return 'active';
  }

}
