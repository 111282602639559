import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({name: 'cleanHtmlSocialWall'})
export class CleanHtmlSocialWall implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {
    }

    public p:string;
    transform(value: string) {
        this.p='<br/>';
        value=value.replace(/•<br>/g, ' ');
        value=value.replace(/<br>/g, ' ');
        value=value.replace(/<br\/>/g, '');
        value=value.replace(/<img/g, '<img style="display:none;" ');
        value=value.replace(/ href/g, ' class="linksocial" style="font-weight: bold;font-size: 14px;line-height: 14px;color:#000000;font-family: source-sans-pro, sans-serif;" target="_blank" href');
       // console.log(value);
        return value;
    }
}